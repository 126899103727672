<template>
  <div>
    <a-table ref="tableRef" :scroll="{x: '100%', y: 260 }" :data-source="tableData" :columns="columns" :components="components" :rowKey="(row) => row.model"
      :row-selection="rowSelection" :pagination="false">
    </a-table>
  </div>
</template>

<script>
import DraggableResizeMixin from '@/mixins/draggableResizeMixin.js';

export default {
  props: {
    selectedRows: {
      type: Array,
      default: () => [],
    },
    selectedIds: {
      type: Array,
      default: () => [],
    },
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [DraggableResizeMixin],
  computed: {
    rowSelection () {
      return {
        type: 'checkbox',
        onChange: (rowkeys, rows) => {
          this.selectedData = rows;

          this.actualSelectedIds = rows.map(r => r.model);
        },
        getCheckboxProps: (record) => {
          let ret = {};

          ret = {
            props: {
              defaultChecked: this.actualSelectedIds.includes(record.model),
            },
          };

          return ret;
        },
      };
    },
  },
  watch: {
    selectedRows: {
      handler (rows) {
        this.selectedData = [...rows];
      },
      immediate: true,
    },
    selectedIds: {
      handler (v) {
        this.actualSelectedIds = [...v];
      },
      immediate: true,
    },
  },
  data () {
    return {
      columns: [
        {
          title: '合同行',
          key: 'index',
          width: 80,
          ellipsis: true,
          customRender: (text, record, index) => `${index + 1}`,
        },
        {
          title: '履约标的',
          dataIndex: 'desc',
          key: 'desc',
          width: 576,
          ellipsis: true,
        },
        {
          title: '待开单金额',
          dataIndex: 'unOrderAmount',
          key: 'unOrderAmount',
          width: 160,
          ellipsis: true,
          customRender: (text) => {
            return text ? (text * 1).toLocaleString() : '';
          },
        },
      ],
      actualSelectedIds: [],
      selectedData: [],
    };
  },
  methods: {
    handleConfirm () {
      this.$emit('handleData', this.selectedData);
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .ant-table-thead {
  &>tr>th {
    padding: 9px 16px;
    font-weight: bold;
    background-color: #F2F4F7;
  }
}

/deep/ .ant-table-tbody {
  tr {
    background-color: #fff;

    &>td {
      padding: 9px 16px;
    }
  }
}

/deep/ .ant-form-item {
  margin-bottom: 12px;
}
</style>

